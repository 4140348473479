* {
  padding: 0;
  margin: 0;
}

:root {
  --slack-color: rgb(63, 15, 64);
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #49274b white;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: #49274b;
  border-radius: 20px;
  border: 3px solid white;
}